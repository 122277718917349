export class MediaAlbum {
  CoverImage: string;
  TitleInHindi: string;
  Title: string;
  CreatedBy: string;
  UpdatedBy: string;
  MediaCategoryId: number;
  IsActive: boolean;
  AlbumDate: any;
  AlbumDateType: any;
  Id: number;

  constructor() {
    this.CoverImage = '';
    this.TitleInHindi = '';
    this.Title = '';
    this.CreatedBy = '';
    this.UpdatedBy = '';
    this.MediaCategoryId = 0;
    this.IsActive = true;
    this.Id = 0;
  }
}
