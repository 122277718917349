import { GspIdCardComponent } from './../gsp-id-card/gsp-id-card.component';
import { ProfileComponent } from './profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EducationProfileComponent } from './education-profile/education-profile.component';

const routes: Routes = [
    {path: '', component: ProfileComponent, children: [
    {path: '', redirectTo: 'profileInfo'},
    {path: 'profileInfo', loadChildren: 'src/app/shared-module/add-members/add-members.module#AddMembersModule'},
    {path: 'eProfle', component: EducationProfileComponent},
    {path: 'changePassword', loadChildren: 'src/app/shared-module/change-password/change-password.module#ChangePasswordModule'},
    {path: 'IdCard', component: GspIdCardComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
