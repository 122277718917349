import { BusinessComponent } from './business.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {path: '', component: BusinessComponent, children: [
    {path: 'bProfile',  loadChildren: 'src/app/shared-module/business-profile/business-profile.module#BusinessProfileModule'},
    {path: 'ViewDirectory', loadChildren: 'src/app/shared-module/business-directory/business-directory.module#BusinessDirectoryModule'},
    {path: 'opportunity', loadChildren: 'src/app/shared-module/business-opportunity/business-opportunity.module#BusinessOpportunityModule'}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessRoutingModule { }
