import { ListenListComponent } from 'src/app/shared-module/listen-list/listen-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule,
    PaginationModule.forRoot()
  ],
  declarations: [ListenListComponent],
  providers: [ListenListComponent]
})
export class ListenlistModule { }
