import { BusinessRoutingModule } from './business-routing.module';
import { BusinessComponent } from './business.component';
import { PaginationModule } from 'ngx-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
  imports: [
    CommonModule, PaginationModule.forRoot(),
    BusinessRoutingModule,
    FormsModule, ReactiveFormsModule, MyDatePickerModule
  ],
  declarations: [BusinessComponent]
})
export class BusinessModule { }
