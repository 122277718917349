export class OccupationDetails {
  OccupationId: number;
  MemberId: number;
  OccupationSubTypeId: number;
  OccupationMainTypeId: number;
  FirmName: string;
  FirmContactNo: number;
  FirmAddress: string;
  FirmDomain: string;
  BussinessCardImageUrl: string;
  Designation: string;
  CountryId: number;
  StateId: number;
  DistrictId: number;
  BusinessDetail: string;
  ReqFirstName: string;
  ReqLastName: string;
  ReqMobileNumber: string;
  ReqEmailId: string;
  CreatedBy: number;
  UpdatedBy: number;
  OccupationTypeId: number;
  OccupationTypeName: string;
  OccupationSubTypeName: string;

  constructor() {
    this.OccupationId = 0;
    this.OccupationSubTypeId = 0;
    this.OccupationMainTypeId = 0;
    this.CountryId = 1;
    this.StateId = 0;
    this.DistrictId = 0;
    this.OccupationTypeId = 0;
    this.OccupationTypeName = '';
    this.OccupationSubTypeName = '';
  }
}
