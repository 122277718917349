import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education-profile',
  templateUrl: './education-profile.component.html',
  styleUrls: ['./education-profile.component.css']
})
export class EducationProfileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
