export class MatrimonialInfo {
  Id: number;
  FullName: string;
  Gotra: string;
  Gender: number;
  GenderText: string;
  DateOfBirth: string;
  Height: number;
  Weight: number;
  BirthPlace: string;
  BirthTime: string;
  Address: string;
  Qualification: number;
  QualificationText: string;
  Working: number;
  WorkingText: string;
  Complexion: number;
  ComplexionText: string;
  Manglik: number;
  ManglikText: string;
  MaritalStatus: number;
  MaritalStatusText: string;
  BiodataUrl: string;
  ImageUrl: string;
  Mobile: string;
  Status: boolean;
  FatherName: string;
  FatherOccupation: string;
  MotherName: string;
  MotherOccupation: string;
  NumberofSisters: number;
  NumberofBrothers: number;
  StateId: number;
  DistrictId: number;
  DistrictName: string;
  CreatedBy: string;
  DOBDateType: any;
  JainType: number;
  ReqFirstName: string;
  ReqLastName: string;
  ReqMobileNumber: string;
  ReqEmailId: string;
  selected: boolean;
  BirthHour: number;
  BirthMin: number;
  BirthAmPm: number;
  CreatorName: string;
  CreatorMobile: string;
  CreatorEmail: string;
  RegisteredUser: string;
  OtherQualification: string;
  PersonalEmail: string;
  PersonalDetail: string;
  PartnerDetail: string;

  constructor() {
    this.Id = 0;
    this.Status = true;
    this.Gender = 0;
    this.Qualification = 0;
    this.Working = 0;
    this.Manglik = 0;
    this.Complexion = 0;
    this.MaritalStatus = 0;
    this.StateId = 0;
    this.DistrictId = 0;
    this.JainType = 0;
    this.Gender = 0;
    this.Qualification = 0;
    this.Working = 0;
    this.Complexion = 0;
    this.Manglik = 0;
    this.MaritalStatus = 0;
    this.StateId = 0;
    this.BirthHour = 0;
    this.BirthMin = 0;
    this.BirthAmPm = 0;
    this.RegisteredUser = '';
    this.Mobile = '';
    this.NumberofBrothers = 0;
    this.NumberofSisters = 0;
    this.FatherOccupation = '';
    this.MotherOccupation = '';
    this.OtherQualification = '';
    this.PersonalDetail = '';
    this.PartnerDetail = '';
    this.PersonalEmail = '';

  }
}
