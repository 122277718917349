import { UserInfo } from './../../model/userInfo';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {

  constructor(public userInfo: UserInfo, private router: Router) { }

  ngOnInit() {
  }
}
