export class Help {
  HelpId: number;
  MemberId: number;
  FirstName: string;
  LastName: string;
  MobileNo: string;
  CountryId: number;
  CountryName: string;
  StateId: number;
  StateName: string;
  DistrictId: number;
  DistrictName: string;
  CityId: number;
  CityName: string;
  PAddress: string;
  Email: string = null;
  ImageUrl: string;
  OccupationMainTypeId: number;
  OccupationTypeName: string;
  Speciality: string;
  OccupationSubTypeId: number;
  OccupationSubTypeName: string;
  FirmName: string;
  FirmContactNo: string;
  FirmAddress: string;
  FirmDomain: string;
  Designation: string;
  ServiceDetail: string;
  ProStateId: number;
  ProState: string;
  ProDistrictId: number;
  ProDistrict: string;
  ProCityId: number;
  IsActive: boolean;
  CreatedBy: number;
  UpdatedBy: number;
  selected: boolean;
  Day: string;
  FromTime: string;
  ToTime: string;

  constructor() {
    const d: Date = new Date();
    this.HelpId = 0;
    this.FirstName = '';
    this.LastName = '';
    this.MobileNo = '';
    this.PAddress = '';
    this.Email = '';
    this.ImageUrl = '';
    this.FirmName = '';
    this.FirmAddress = '';
    this.FirmContactNo = '';
    this.FirmDomain = '';
    this.Designation = '';
    this.ServiceDetail = '';
    this.MemberId = 0;
    this.CountryId = 0;
    this.StateId = 0;
    this.DistrictId = 0;
    this.CityId = 0;
    this.OccupationMainTypeId = 0;
    this.OccupationSubTypeId = 0;
    this.ProStateId = 0;
    this.ProDistrictId = 0;
    this.ProCityId = 0;
    this.IsActive = true;
    this.Speciality = '';
  }
}
