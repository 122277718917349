import { PaginationModule } from 'ngx-bootstrap';
import { GspIdCardModule } from './../gsp-id-card/gsp-id-card.module';
import { ProfileComponent } from './profile.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileRoutingModule } from './profile-routing.module';
import { EducationProfileComponent } from './education-profile/education-profile.component';
import { FieldErrorDisplayModule } from './../../shared-module/field-error-display/field-error-display.module';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
  imports: [
    CommonModule, PaginationModule.forRoot(),
    ProfileRoutingModule,
    FormsModule, ReactiveFormsModule, FieldErrorDisplayModule, MyDatePickerModule, GspIdCardModule
  ],
  declarations: [ProfileComponent, EducationProfileComponent]
})
export class ProfileModule { }
