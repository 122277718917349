import { UserInfo } from './../../model/userInfo';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(public userInfo: UserInfo, private router: Router) { }

  ngOnInit() {
  }
  isLinkActive(url) {
    const queryParamsIndex = this.router.url.indexOf(url);
    if (queryParamsIndex === -1) {
        return false;
    } else {
      return true;
    }
  }
  signOut(): void {
    this.userInfo.setAllNull();
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('IsRememberMe', 'false');
    this.router.navigate(['user']);
  }
}
