import { appConfig } from 'src/app/app.config';
import { PaginationParams } from '../../model/pagination';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../helperService/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listen-list',
  templateUrl: './listen-list.component.html',
  styleUrls: ['./listen-list.component.css']
})
export class ListenListComponent implements OnInit {

  ShowMediaCover: boolean;
  paginationParams: any;
  audioMediaCoverList = [];
  audioMediaList = [];
  activeAudioCategory;
  activeMediaId;
  HeaderText: string;
  constructor(private _commonService: CommonService, private _activeRoute: ActivatedRoute) { }

  apiUrl(): string {
    return appConfig.apiUrl;
  }
  ngOnInit() {
    this.ShowMediaCover = true;
    this.paginationParams = new PaginationParams();
    this.paginationParams.PageSize = 8;

    this._activeRoute.url.subscribe(res => {
      if (res[0].path === 'chikitsa') {
        this.HeaderText = 'Swadesi Chikitsa';
      }
    });

    this._activeRoute.data.subscribe(res => {
      this.activeAudioCategory = res.audiocategory;
      this.onCategoryChange(this.activeAudioCategory);
    });
  }

  onCategoryChange(categoryID) {
    this.ShowMediaCover = true;
    this._commonService.GetMediaByCategory(categoryID, this.paginationParams.PageNo, this.paginationParams.PageSize).subscribe(res => {
      this.audioMediaCoverList = res.Data;
      this.paginationParams  = res.Paging;
    });
  }

  openAudioMediaCategory(mediaId) {
    this.activeMediaId = mediaId;
    this._commonService.GetAudioMediaItems(mediaId, this.paginationParams.PageNo, this.paginationParams.PageSize).subscribe(res => {
      this.ShowMediaCover = false;
      this.audioMediaList = res.Data;
      this.paginationParams  = res.Paging;
    });
  }

  audioPlay(evt: any) {
    const audioElements = document.getElementsByClassName('auioplayer');
    for (let i = 0; i < audioElements.length; i++) {
      const audioElement: any = audioElements[i];
      if (audioElement.id !== evt.target.id) {
      audioElement.pause();
      }
    }
  }

  onPageSizeChanged(e) {
    this.paginationParams = new PaginationParams();
    this.paginationParams.PageSize = e.target.value;
  }

  pageChanged(event: any): void {
    this.paginationParams.PageNo = event.page;
    if (this.ShowMediaCover === true) {
      this.onCategoryChange(this.activeAudioCategory);
    } else if (this.ShowMediaCover === false) {
      this.openAudioMediaCategory(this.activeMediaId);
    }
  }
}

