export class UpdatesDetail {
  Id: number;
  Title: string;
  TitleInHindi: string;
  Description: string;
  DescriptionInHindi: string;
  Location: string;
  Latitude: string;
  Longitude: string;
  ImageUrl: string;
  UpdateTypeId: number;
  DateStart: string;
  DateStartType: any;
  DateEnd: string;
  DateEndType: any;
  CreatedBy: number;
  UpdatedBy: number;
  LocationLink: string;

  constructor() {
    this.Id = 0;
    this.Title = null;
    this.TitleInHindi = null;
    this.Description = null;
    this.DescriptionInHindi = null;
    this.Location = null;
    this.Latitude = null;
    this.Longitude = null;
    this.ImageUrl = null;
    this.UpdateTypeId = null;
    this.DateStart = null;
    this.DateEnd = null;
    this.CreatedBy = 0;
    this.UpdatedBy = 0;
    this.LocationLink = null;
  }

}


